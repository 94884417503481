
import { Vue } from "vue-class-component";

import EN from "../assets/languages/EN.json";
import KA from "../assets/languages/KA.json";
import { LanguageHandler } from "./language";

export default class NavBar extends Vue {
  public changeLanguage(languageIndex: number) {
    if (languageIndex == 0) {
      localStorage.setItem("currentLanguage", "EN");
      document
        .querySelector(".settings-language-choice")
        ?.classList.add("invisible");
      document.getElementById("currentLanguage")!.innerHTML = "EN";
      this.SetLanguage(0);
      // Reload the current page
      location.reload();
    } else {
      localStorage.setItem("currentLanguage", "KA");
      document
        .querySelector(".settings-language-choice")
        ?.classList.add("invisible");
      document.getElementById("currentLanguage")!.innerHTML = "GE";
      this.SetLanguage(1);
      // Reload the current page
      location.reload();
    }
  }

  public toggleLanguage() {
    document
      .querySelector(".settings-language-choice")
      ?.classList.toggle("invisible");
  }
  public handleClick(): void {
    // Your code to execute when the button is clicked
    document
      .getElementById("navbarBurgerContainer")!
      .classList.toggle("burger-active");
    document
      .querySelector(".burger-menu-line")
      ?.classList.toggle("yellow-background");
  }

  public textContent: string[] = [];
  public SetLanguage(langIndex: number) {
    if (langIndex == 0) {
      this.textContent = EN.Navbar;
    } else {
      this.textContent = KA.Navbar;
    }
  }

  private InitLanguage() {
    if (localStorage.getItem("currentLanguage") == "EN") {
      this.textContent = EN.Navbar;
    } else if (localStorage.getItem("currentLanguage") == "KA") {
      this.textContent = KA.Navbar;
    }
  }

  mounted() {
    this.$nextTick(() => {
      new LanguageHandler();
      this.InitLanguage();
      // Access the DOM element here
      let i = 0;
      this.textContent.forEach((e) => {
        document.querySelectorAll(".navbar-" + i).forEach((element) => {
          element.innerHTML = e;
        });
        i += 1;
      });
      i = 0;
    });

    document.querySelector(".navbar-4")?.addEventListener("click", () => {
      document
        .getElementById("navbarBurgerContainer")
        ?.classList.remove("burger-active");
    });

    document.getElementById("homeButtonLogo")?.addEventListener("click", () => {
      document
        .getElementById("navbarBurgerContainer")
        ?.classList.remove("burger-active");
    });
  }
}

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".router-link-class").forEach((e) => {
    e.addEventListener("click", () => {
      document
        .getElementById("navbarBurgerContainer")!
        .classList.toggle("burger-active");
      document
        .querySelector(".burger-menu-line")
        ?.classList.toggle("yellow-background");
    });
  });
});
