
import { Vue } from "vue-class-component";
import EN from "../assets/languages/EN.json";
import KA from "../assets/languages/KA.json";

export default class ContactsPage extends Vue {
  mounted() {
    document.addEventListener("DOMContentLoaded", () => {
      document.querySelectorAll("li").forEach((element) => {
        element.addEventListener("click", () => {
          navigator.clipboard.writeText(element.innerHTML);
          document.getElementById("copy-alert-id")?.classList.add("copy-alert");
          setTimeout(() => {
            document
              .getElementById("copy-alert-id")
              ?.classList.remove("copy-alert");
          }, 2000);
        });
      });
    });

    this.$nextTick(() => {
      if (localStorage.getItem("currentLanguage") == "EN") {
        let i = 0;
        EN.contactsV.forEach((e) => {
          document.querySelectorAll(".contact-item-" + i).forEach((element) => {
            element.innerHTML = e;
          });
          i += 1;
        });
      } else if (localStorage.getItem("currentLanguage") == "KA") {
        let i = 0;
        KA.contactsV.forEach((e) => {
          document.querySelectorAll(".contact-item-" + i).forEach((element) => {
            element.innerHTML = e;
          });
          i += 1;
        });
      }
    });
  }
}
