
import { defineComponent } from "vue";
import NavBar from "@/components/NavBar.vue"; // @ is an alias to /src
import OpenContainer from "@/components/OpenContainer.vue"; // @ is an alias to /src
import PageFooter from "./components/PageFooter.vue";
import { LanguageHandler } from "./components/language";
import { menuData } from "./components/menuItems";

export default defineComponent({
  components: {
    NavBar,
    OpenContainer,
    PageFooter,
  },
  data() {
    return {
      renderDecorations: menuData.renderDecorations,
    };
  },
  methods: {
    UpdateView() {
      menuData.renderDecorations = !window.location.href.includes("/menu/");
      this.renderDecorations = menuData.renderDecorations;
      // console.log(this.renderDecorations);
    },
  },
  created() {
    addEventListener("load", () => {
      this.UpdateView();
    });
  },
});

addEventListener("resize", () => {
  const root = document.documentElement;

  // Update the custom variables
  root.style.setProperty("--window-width", window.innerWidth + "px");
  root.style.setProperty("--window-height", window.innerHeight + "px");
});
