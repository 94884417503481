import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-abbb6b02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "gallery" }
const _hoisted_2 = ["src", "onClick"]
const _hoisted_3 = {
  key: 0,
  class: "full-page-container"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "full-page-nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "image-container",
        key: index
      }, [
        _createElementVNode("img", {
          src: _ctx.getImagePath(image),
          alt: "Gallery Image",
          onClick: ($event: any) => (_ctx.showFullPage(index))
        }, null, 8, _hoisted_2)
      ]))
    }), 128)),
    (_ctx.showFullImage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.getImagePath(_ctx.images[_ctx.currentIndex]),
            alt: "Full Page Image",
            class: "full-page-image"
          }, null, 8, _hoisted_4),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", {
              class: "arrow left-arrow",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigate(-1)))
            }, "←"),
            _createElementVNode("span", {
              class: "arrow right-arrow",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigate(1)))
            }, "→"),
            _createElementVNode("span", {
              class: "close-button",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeFullPage && _ctx.closeFullPage(...args)))
            }, "✖")
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}