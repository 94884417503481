
import { Vue } from "vue-class-component";

export default class GalleryPage extends Vue {
  images: string[] = [
    "GYM1.jpg",
    "GYM2.jpg",
    "GYM3.jpg",
    "GYM4.jpg",
    "GYM5.jpg",
    "GYM6.jpg",
    "GYM7.jpg",
    "GYM8.jpg",
    "GYM9.jpg",
    "GYM10.jpg",
    "GYM11.jpg",
    "GYM12.jpg",
    "GYM13.jpg",
    "GYM14.jpg",
    "GYM15.jpg",
    "GYM16.jpg",
    "GYM17.jpg",
  ];

  showFullImage = false;
  currentIndex = 0;

  getImagePath(image: string): string {
    return `./media/${image}`;
  }

  showFullPage(index: number): void {
    this.showFullImage = true;
    this.currentIndex = index;
  }

  closeFullPage(): void {
    this.showFullImage = false;
  }

  navigate(offset: number): void {
    const newIndex = this.currentIndex + offset;
    if (newIndex >= 0 && newIndex < this.images.length) {
      this.currentIndex = newIndex;
    }
  }
}
