
import { Options, Vue } from "vue-class-component";
import EN from "../assets/languages/EN.json";
import KA from "../assets/languages/KA.json";

export default class HomeView extends Vue {
  mounted() {
    this.$nextTick(() => {
      if (localStorage.getItem("currentLanguage") == "EN") {
        let i = 0;
        EN.homeV.forEach((e) => {
          document.querySelectorAll(".home-" + i).forEach((element) => {
            element.innerHTML = e;
          });
          i += 1;
        });
      } else if (localStorage.getItem("currentLanguage") == "KA") {
        let i = 0;
        KA.homeV.forEach((e) => {
          document.querySelectorAll(".home-" + i).forEach((element) => {
            element.innerHTML = e;
          });
          i += 1;
        });
      }
    });
  }
}
