
import { Vue } from "vue-class-component";
import EN from "../assets/languages/EN.json";
import KA from "../assets/languages/KA.json";

export default class ProductView extends Vue {
  mounted() {
    this.$nextTick(() => {
      if (localStorage.getItem("currentLanguage") == "EN") {
        let i = 0;
        EN.productV.forEach((e) => {
          document.querySelectorAll(".product-" + i).forEach((element) => {
            element.innerHTML = e;
          });
          i += 1;
        });
      } else if (localStorage.getItem("currentLanguage") == "KA") {
        let i = 0;
        KA.productV.forEach((e) => {
          document.querySelectorAll(".product-" + i).forEach((element) => {
            element.innerHTML = e;
          });
          i += 1;
        });
      }
    });
    ResizePage();
  }
}

addEventListener("scroll", () => {
  const limit = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  );
  HandleScroll(window.scrollY / (limit - (500 + 2 * window.screenY)));
});

addEventListener("resize", () => {
  ResizePage();
});

document.addEventListener("DOMContentLoaded", () => {
  const limit = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  );
  HandleScroll(window.scrollY / (limit - (500 + 2 * window.screenY)));
  ResizePage();
});

function HandleScroll(scrolledTo: number) {
  if (scrolledTo < 0.3) {
    document.getElementById("productImg0")?.classList.add("scrolledTo");
    document.getElementById("productImg0")?.classList.remove("scrolledUp");
    document.getElementById("productImg1")?.classList.remove("scrolledTo");
    document.getElementById("productImg1")?.classList.remove("scrolledUp");
    document.getElementById("productImg2")?.classList.remove("scrolledTo");
    document.getElementById("productImg2")?.classList.remove("scrolledUp");
    document.getElementById("productImg3")?.classList.remove("scrolledTo");
    document.getElementById("productImg3")?.classList.remove("scrolledUp");
  } else if (scrolledTo < 0.55) {
    document.getElementById("productImg0")?.classList.remove("scrolledTo");
    document.getElementById("productImg0")?.classList.add("scrolledUp");
    document.getElementById("productImg1")?.classList.add("scrolledTo");
    document.getElementById("productImg1")?.classList.remove("scrolledUp");
    document.getElementById("productImg2")?.classList.remove("scrolledTo");
    document.getElementById("productImg2")?.classList.remove("scrolledUp");
    document.getElementById("productImg3")?.classList.remove("scrolledTo");
    document.getElementById("productImg3")?.classList.remove("scrolledUp");
  } else if (scrolledTo < 0.63) {
    document.getElementById("productImg0")?.classList.remove("scrolledTo");
    document.getElementById("productImg0")?.classList.add("scrolledUp");
    document.getElementById("productImg1")?.classList.remove("scrolledTo");
    document.getElementById("productImg1")?.classList.add("scrolledUp");
    document.getElementById("productImg2")?.classList.add("scrolledTo");
    document.getElementById("productImg2")?.classList.remove("scrolledUp");
    document.getElementById("productImg3")?.classList.remove("scrolledTo");
    document.getElementById("productImg3")?.classList.remove("scrolledUp");
  } else {
    document.getElementById("productImg0")?.classList.remove("scrolledTo");
    document.getElementById("productImg0")?.classList.add("scrolledUp");
    document.getElementById("productImg1")?.classList.remove("scrolledTo");
    document.getElementById("productImg1")?.classList.add("scrolledUp");
    document.getElementById("productImg2")?.classList.remove("scrolledTo");
    document.getElementById("productImg2")?.classList.add("scrolledUp");
    document.getElementById("productImg3")?.classList.add("scrolledTo");
    document.getElementById("productImg3")?.classList.remove("scrolledUp");
  }
}

function ResizePage() {
  if (window.innerWidth > 1000) {
    document.getElementById("productImg0")?.classList.remove("invisible");
    document.getElementById("productImg1")?.classList.remove("invisible");
    document.getElementById("productImg2")?.classList.remove("invisible");
    document.getElementById("productImg3")?.classList.remove("invisible");
  } else {
    document.getElementById("productImg0")?.classList.add("invisible");
    document.getElementById("productImg1")?.classList.add("invisible");
    document.getElementById("productImg2")?.classList.add("invisible");
    document.getElementById("productImg3")?.classList.add("invisible");
  }
}
