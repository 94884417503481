export class LanguageHandler {
  constructor() {
    if (localStorage.getItem("currentLanguage") == "EN") {
      this.SetLanguage(0);
    } else if (localStorage.getItem("currentLanguage") == "KA") {
      this.SetLanguage(1);
    } else {
      this.SetLanguage(0);
    }
  }

  public SetLanguage(chosenLanguage: number) {
    if (chosenLanguage == 0) {
      localStorage.setItem("currentLanguage", "EN");
      document.getElementById("currentLanguage")!.innerHTML = "EN";
    } else {
      localStorage.setItem("currentLanguage", "KA");
      document.getElementById("currentLanguage")!.innerHTML = "GE";
    }
  }
}
