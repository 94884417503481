
import { Vue } from "vue-class-component";

import EN from "../assets/languages/EN.json";
import KA from "../assets/languages/KA.json";

export default class OpenContainer extends Vue {}

const imgs: { [key: string]: string } = {
  home: "7",
  contacts: "5",
  about: "9",
  product: "16",
  // gallery: "4",
  gallery: "15",
  buy: "1",
};

let viewName = window.location.href;
let oldViewName = "";
document.addEventListener("DOMContentLoaded", () => {
  viewName = viewName.split("/")[viewName.split("/").length - 1];
  if (viewName == "") {
    viewName = "home";
  }
  oldViewName = viewName;
  SetStartPage();
  document.getElementById("site-navigator")!.addEventListener("click", () => {
    viewName = window.location.href;
    viewName = viewName.split("/")[viewName.split("/").length - 1];
    if (viewName == "") {
      viewName = "home";
    }
    if (viewName != oldViewName) {
      SetStartPage();
      oldViewName = viewName;
    }
  });

  document
    .getElementById("navbarBurgerContainer")!
    .addEventListener("click", () => {
      viewName = window.location.href;
      viewName = viewName.split("/")[viewName.split("/").length - 1];
      if (viewName == "") {
        viewName = "home";
      }
      if (viewName != oldViewName) {
        SetStartPage();
        oldViewName = viewName;
      }
    });
});

function SetStartPage(this: any) {
  window.scrollTo(0, 0);
  if (localStorage.getItem("currentLanguage") == "EN") {
    document.querySelector(".open-container-location")!.innerHTML = (
      EN.OpenContainer[0] as Record<string, string>
    )[viewName];
    document.querySelector(".membership-button")!.innerHTML = "Become A Member";
  } else {
    document.querySelector(".open-container-location")!.innerHTML = (
      KA.OpenContainer[0] as Record<string, string>
    )[viewName];
    document.querySelector(".membership-button")!.innerHTML = "გაწევრიანდი";
  }
  document
    .querySelector(".open-container-location")!
    .classList.remove("revealDiv");
  document
    .querySelector(".open-container-location")!
    .classList.add("revealDiv");
  if (localStorage.getItem("currentLanguage") == "EN") {
    document.querySelector(".open-container-title")!.innerHTML = (
      EN.OpenContainer[1] as Record<string, string>
    )[viewName];
  } else {
    document.querySelector(".open-container-title")!.innerHTML = (
      KA.OpenContainer[1] as Record<string, string>
    )[viewName];
  }
  document.head.appendChild(
    Object.assign(document.createElement("style"), {
      textContent:
        "#openContainerBackground { background-image: url('../media/GYM" +
        imgs[viewName] +
        ".jpg'); }",
    })
  );
  document.getElementById("openContainerBackground")!.animate(
    [
      { filter: "brightness(0)", offset: 0 },
      { filter: "brightness(1)", offset: 1 },
    ],
    { duration: 400, iterations: 1 }
  );
}
