import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_OpenContainer = _resolveComponent("OpenContainer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PageFooter = _resolveComponent("PageFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.renderDecorations)
      ? (_openBlock(), _createBlock(_component_NavBar, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.renderDecorations)
      ? (_openBlock(), _createBlock(_component_OpenContainer, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    (_ctx.renderDecorations)
      ? (_openBlock(), _createBlock(_component_PageFooter, { key: 2 }))
      : _createCommentVNode("", true)
  ], 64))
}